import React from 'react'
import { Row, Col, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/photobooth/actions'

const mapStateToProps = ({ photobooth }) => ({ photobooth })

/* eslint-disable no-plusplus */
@connect(mapStateToProps)
class Showcase extends React.Component {
  retake = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        imageSrc: '',
      },
    })
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  goNext = () => {
    const {
      dispatch,
      photobooth: { currentStep,imageSrc,  overlayApplied, overlayURL },
    } = this.props

    if (overlayApplied) {
      const canvas = document.createElement('canvas')
      canvas.id = 'overlayImage'
      canvas.width = 1920
      canvas.height = 1080
      const ctx = canvas.getContext('2d')

      const image = new Image()
      image.src = imageSrc
      image.onload = function() {
        ctx.drawImage(image, 0, 0)
      }
      

      const overlayImage = new Image()
      overlayImage.src = overlayURL
      overlayImage.onload = () => {
        ctx.drawImage(overlayImage, 0, 0)
        const finalImage = canvas.toDataURL()
        dispatch({
          type: actions.SET_STATE,
          payload: {
            currentStep: currentStep + 1,
            imageSrc: finalImage,
          },
        })

        const imgBlob = this.dataURLtoFile(finalImage,`KAS Frame-${Date.now()}-photobooth.png`)
        dispatch({
          type: actions.UPLOAD_PHOTOBOOTH_IMAGE,
          payload: {
            imageString: imgBlob
          },
        })
      }
    }
    else{
      dispatch({
        type: actions.SET_STATE,
        payload: {
          currentStep: currentStep + 1,
        },
      })
    }

    dispatch({
      type: actions.PHOTOBOOTH_ANALYTICS
    })
  }

  render() {
    const {
      photobooth: { imageSrc,  overlayApplied, overlayURL},
    } = this.props
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center">
            {overlayApplied ? <img src={overlayURL} style={{ width: '100%', borderRadius: '15px 15px 0 0' }} alt="overlayImage" className="overlayimage" /> : ''}
            <img src={imageSrc} alt="newImage" style={{ width: '100%', borderRadius: '15px 15px 0 0' }} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Looking Awesome!</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button shape="circle" onClick={this.retake} size="large">
              Retake
            </Button>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.retake} size="medium">
              <LeftOutlined /> &nbsp; Back
            </Button>
          </Col>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center">
            <Button type="primary" onClick={this.goNext} size="medium">
              Next &nbsp; <RightOutlined />
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default Showcase
