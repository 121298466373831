import React from 'react'
import { Row, Col, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/photobooth/actions'
// import mosaicActions from 'redux/virtual/mosaic/actions'
import Base64Downloader from 'react-base64-downloader'

const mapStateToProps = ({ photobooth }) => ({ photobooth })

@connect(mapStateToProps)
class Download extends React.Component {
  // goBack = () => {
  //   const {dispatch, photobooth: {currentStep}} = this.props
  //   dispatch({
  //     type: actions.SET_STATE,
  //     payload:{
  //       currentStep: currentStep - 1,
  //     }
  //   })
  // }

  resetPhotobooth = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 0,
        imageSrc: '',
        webcamLoading: false,
        overlayApplied: false,
        overlayURL: '',
        uploadedImageURL: '',
        imgURLloading: false
      },
    })
  }

  // shareFacebook = () => {
  //   const {
  //     photobooth: { uploadedImageURL},
  //   } = this.props
  //   window.open(`https://www.facebook.com/sharer/sharer.php?u=${uploadedImageURL}&quote=Out%20On%20an%20Odyssey%20with%20Flipkart.%0ASharing%20my%20Picture%20Perfect%20moment%21%20%0A%0A%23FlipkartOOO2020%20%23FlipkartDayParty`,'_blank')
  // }

  // initiateMosaicUpload = () => {
  //   const {
  //     dispatch,
  //     photobooth: { imageSrc },
  //   } = this.props

  //   dispatch({
  //     type: actions.SET_STATE,
  //     payload: {
  //       mosaicUploadLoader: true,
  //     },
  //   })

  //   dispatch({
  //     type: mosaicActions.UPLOAD_MOSAIC_IMAGE,
  //     payload: {
  //       uploadtype: 'base64',
  //       imageString: imageSrc
  //     },
  //   })
  // }
  // downloadFile = () => {
  //   const {photobooth: {imageSrc}} = this.props

  // }

  render() {
    const {
      photobooth: { imageSrc },
    } = this.props
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center">
            <img src={imageSrc} alt="newImage" style={{ width: '100%', borderRadius: '15px 15px 0 0' }} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px', padding: '15px' }}>
          <Col span={24}>
            <h3>Download</h3>
            <h6>Looking Great!</h6>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center downloadButton">
            <Base64Downloader base64={imageSrc} downloadName="KAS Frame">
              <Button shape="circle" size="large" className="actualDownloadButton">
                <DownloadOutlined />
              </Button>
            </Base64Downloader>
          </Col>
          {/* <Col span={12} className="text-center">
            <Tooltip position="top" title="Upload photo to Mosaic">
              <Button shape="circle" size="large" onClick={this.initiateMosaicUpload} loading={mosaicUploadLoader}>
              Upload
              </Button>
            </Tooltip>
          </Col> */}
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.resetPhotobooth} size="medium">
              Once More!
            </Button>
          </Col>
          <Col span={8} className="text-center" />
        </Row>
      </>
    )
  }
}

export default Download
