import React from 'react'
import { Row, Col, Button, Tooltip, Tag } from 'antd'
import { 
  // EyeOutlined, 
  DownloadOutlined, 
  DeleteFilled } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/event/actions'
// import './doculist.less'

const docuTypes = {
  pdf: {
    title: 'PDF',
    icon: 'pdf',
  },
  mp4: {
    title: 'video',
    icon: 'mp4',
  },
  ppt: {
    title: 'Powerpoint',
    icon: 'powerpoint',
  },
}

@connect(({ virtualEvent, virtualSettings }) => ({ virtualEvent, virtualSettings }))
class DocumentList extends React.Component {
  downloadFile = event => {
    const { dispatch } = this.props
    const heading = event.target.getAttribute('data-heading')
    const extension = event.target.getAttribute('data-extension')
    dispatch({
      type: actions.DOWNLOAD_FILE,
      payload: {
        url: event.target.getAttribute('data-url'),
        filename: `${heading}.${extension}`,
      },
    })
  }

  startQuickView = event => {
    const fileURL = event.target.getAttribute('data-url')
    const heading = event.target.getAttribute('data-heading')
    const { dispatch } = this.props

    dispatch({
      type: actions.SET_STATE,
      payload: {
        isQuickViewOpen: true,
        currentFile: {
          fileURL,
          heading,
        },
      },
    })
  }

  openSideways = event => {
    const fileURL = event.target.getAttribute('data-url')
    window.open(`https://docs.google.com/gview?url=${fileURL}`,'_blank')
  }

  removefromBriefcase = event => {
    const id = event.target.getAttribute('data-id')
    const {
      dispatch,
      virtualEvent: { briefcase },
    } = this.props
    const tempBriefCase = briefcase
    delete tempBriefCase[id]
    dispatch({
      type: actions.SET_STATE,
      payload: {
        briefcase: tempBriefCase,
      },
    })

    dispatch({
      type: actions.REMOVE_FROM_BRIEFCASE,
      payload: {
        id,
      },
    })
  }

  getDocuItem = item => {
    const {
      virtualSettings: { isVirtualMobileView },
    } = this.props
    const { fileURL } = item[1]
    if(fileURL !== undefined && fileURL !== "")
    {
      const extension = fileURL.substring(fileURL.lastIndexOf('.') + 1).toLowerCase()
      const imagebaseURL = 'resources/images/svgicons/'
      const imgURL = `${imagebaseURL}${docuTypes[extension].icon}.svg`
      return (
        <div className="downloadItem">
          <Row gutter={0} className="downloadHeader" justify="space-around" align="middle">
            <Col xs={2} md={2} lg={2} xl={2}>
              <img src={imgURL} style={{ width: '80%' }} alt={`${extension}-file`} />)
            </Col>
            {!isVirtualMobileView ? (
              <>
                <Col md={16} lg={16} xl={16} className="documentTitle">
                  <h3>{item[1].heading}</h3>
                  <Tag>Collected @{item[1].collectedFrom}</Tag>
                </Col>
              </>
            ) : (
              <Col xs={12} />
            )}

            <Col xs={3} md={2} lg={2} xl={2} className="text-center">
              <Tooltip placement="topLeft" title="Download File" arrowPointAtCenter>
                <Button
                  type="primary"
                  shape="circle"
                  data-url={item[1].fileURL}
                  data-heading={item[1].heading}
                  data-extension={extension}
                  icon={<DownloadOutlined />}
                  size={isVirtualMobileView ? 'small' : 'medium'}
                  onClick={this.downloadFile}
                />
              </Tooltip>
            </Col>
            {/* <Col xs={3} md={2} lg={2} xl={2} className="text-center">
              <Tooltip placement="topLeft" title="Quick View" arrowPointAtCenter>
                <Button
                  type="primary"
                  shape="circle"
                  data-url={item[1].fileURL}
                  data-heading={item[1].heading}
                  icon={<EyeOutlined />}
                  size={isVirtualMobileView ? 'small' : 'medium'}
                  // onClick={this.startQuickView}
                  onClick={this.openSideways}
                />
              </Tooltip>
            </Col> */}
            <Col xs={3} md={2} lg={2} xl={2} className="text-center">
              <Tooltip placement="topLeft" title="Remove from Briefcase" arrowPointAtCenter>
                <Button
                  type="primary"
                  shape="circle"
                  data-id={item[0]}
                  icon={<DeleteFilled />}
                  size={isVirtualMobileView ? 'small' : 'medium'}
                  onClick={this.removefromBriefcase}
                  danger
                />
              </Tooltip>
            </Col>
            {isVirtualMobileView ? (
              <>
                <Col xs={24} className="documentTitle" style={{ paddingTop: '10px' }}>
                  <h3>{item[1].heading}</h3>
                  <Tag>Collected @{item[1].collectedFrom}</Tag>
                </Col>
              </>
            ) : (
              ''
            )}
          </Row>
          <Row className="sessionContent">
            <Col span={24}>
              <p>{item[1].description}</p>
            </Col>
          </Row>
        </div>
      )
    }
    return ''
  }

  render() {
    const {
      virtualEvent: { briefcase },
    } = this.props

    const showcase = Object.entries(briefcase).map(key => {
      return this.getDocuItem(key)
    })

    return <>{showcase}</>
  }
}

export default DocumentList
