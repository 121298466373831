import React from 'react'
import { Row, Col, Button } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import actions from 'redux/virtual/photobooth/actions'
import Webcam from 'react-webcam'

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
}

const overlays = {
  1: {
    imagePath: 'resources/images/overlay/overlay01.png',
    thumbnail: 'resources/images/overlay/overlay01.png',
  },
  2: {
    imagePath: 'resources/images/overlay/overlay02.png',
    thumbnail: 'resources/images/overlay/overlay02.png',
  },
  3: {
    imagePath: 'resources/images/overlay/overlay03.png',
    thumbnail: 'resources/images/overlay/overlay03.png',
  },
  // 4: {
  //   imagePath: 'resources/images/overlay/overlay04.png',
  //   thumbnail: 'resources/images/overlay/preview/overlay04.jpg',
  // },
  // 5: {
  //   imagePath: 'resources/images/overlay/overlay05.png',
  //   thumbnail: 'resources/images/overlay/preview/overlay05.jpg',
  // },
  // 6: {
  //   imagePath: 'resources/images/overlay/overlay06.png',
  //   thumbnail: 'resources/images/overlay/preview/overlay06.jpg',
  // },
  // 7: {
  //   imagePath: 'resources/images/overlay/overlay07.png',
  //   thumbnail: 'resources/images/overlay/preview/overlay07.jpg',
  // },
  // 8: {
  //   imagePath: 'resources/images/overlay/overlay08.png',
  //   thumbnail: 'resources/images/overlay/preview/overlay08.jpg',
  // },
}



const mapStateToProps = ({ photobooth }) => ({ photobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate */
@connect(mapStateToProps)
class Capture extends React.Component {
  state = {
    countdown: 3,
    startCountdown: false,
    overlayApplied: true,
    currentoverlay: 1,
  }

  setRef = webcam => {
    this.webcam = webcam
  }

  capture = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    const { countdown,currentoverlay, overlayApplied } = this.state
    this.setState({ startCountdown: true })
    setInterval(() => {
      this.setState({ countdown: this.state.countdown - 1 })
    }, 1000)
    setTimeout(() => {
      const imageSrcCaptured = this.webcam.getScreenshot()
        dispatch({
          type: actions.SET_STATE,
          payload: {
            currentStep: currentStep + 1,
            imageSrc: imageSrcCaptured,
            webcamLoading: true,
            overlayApplied,
            overlayURL: overlays[currentoverlay].imagePath
          },
        })
      // dispatch({
      //   type: actions.SET_STATE,
      //   payload: {
      //     currentStep: currentStep + 1,
      //     imageSrc,
      //     webcamLoading: true,
      //   },
      // })
    }, countdown * 1000 + 100)
  }

  applyOverlay = event => {
    const index = event.target.getAttribute('data-index')
    this.setState({ overlayApplied: true, currentoverlay: index })
  }

  disableLoader = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        webcamLoading: false,
      },
    })
  }
  

  goNext = () => {
    const {
      dispatch,
      photobooth: { currentStep, imageSrc },
    } = this.props
    const { currentoverlay, overlayApplied } = this.state

    if (overlayApplied) {
      const canvas = document.createElement('canvas')
      canvas.id = 'overlayImage'
      canvas.width = 1920
      canvas.height = 1080
      const ctx = canvas.getContext('2d')

      const image = new Image()
      image.onload = function() {
        ctx.drawImage(image, 0, 0)
      }
      image.src = imageSrc

      const overlayImage = new Image()
      overlayImage.src = overlays[currentoverlay].imagePath
      overlayImage.onload = () => {
        ctx.drawImage(overlayImage, 0, 0)
        const finalImage = canvas.toDataURL()
        dispatch({
          type: actions.SET_STATE,
          payload: {
            imageSrc: finalImage,
          },
        })
      }
    }
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep + 1,
      },
    })
  }

  render() {
    const {
      photobooth: { webcamLoading },
    } = this.props
    const { countdown, startCountdown,currentoverlay, overlayApplied } = this.state
    const videoConstraints = {
      width: 1920,
      height: 1080,
      facingMode: 'user',
    }
    const slides = Object.entries(overlays).map(key => {
      return (
        <div>
          <img
            src={key[1].thumbnail}
            alt={`overlay${key[0]}`}
            data-index={key[0]}
            onClick={this.applyOverlay}
            style={{ width: '100%' }}
          />
        </div>
      )
    })
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center" style={{ width: '100%', minHeight: '100px' }}>
            {webcamLoading ? <Loader /> : ''}
            {startCountdown ? <h2 className="countdown">{countdown}</h2> : ''}
            {overlayApplied ? (
              <img
                src={overlays[currentoverlay].imagePath}
                alt="overlayImage"
                className="overlayimage"
              />
            ) : (
              ''
            )}
            <Webcam
              audio={false}
              imageSmoothing
              mirrored
              style={{ width: '100%', borderRadius: '15px 15px 0 0' }}
              ref={this.setRef}
              minScreenshotHeight={1080}
              minScreenshotWidth={1920}
              screenshotQuality={1}
              screenshotFormat="image/jpg"
              onUserMedia={this.disableLoader}
              videoConstraints={videoConstraints}
            />
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={24} className="containerSlider">
            <Slider {...settings}>{slides}</Slider>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Take a selfie</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button
              type="primary"
              shape="circle"
              onClick={this.capture}
              icon={<CameraOutlined />}
              size="large"
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Capture
