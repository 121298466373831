import React from 'react'
import { connect } from 'react-redux'
import { Modal, Row, Col, Skeleton, Button } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { DownloadOutlined, CloseCircleOutlined } from '@ant-design/icons'
import actions from 'redux/virtual/event/actions'
import QuickView from 'pages/virtual/event/main/LayoutComponents/QuickView'
import DocumentList from './LayoutComponents/DocumentList'

// import './download.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ virtualSettings, virtualEvent }) => ({ virtualSettings, virtualEvent }))
class Briefcase extends React.Component {
  openDownloadable = event => {
    const podID = event.target.getAttribute('data-pathTo')
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentClickSection: podID,
        isDocumentListVisible: true,
      },
    })
  }

  downloadBriefcase = () => {
    const {
      virtualEvent: { briefcase },
    } = this.props

    Object.entries(briefcase).map(key => {
      window.open(key[1].fileURL);
      return ''
    })
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isBriefcaseOpen',
        value: false,
      },
    })
  }

  sendEmail = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        briefcaseEmailLoading: true,
      },
    })
    dispatch({
      type: actions.SEND_BRIEFCASE_EMAIL,
      payload: {},
    })
  }

  render() {
    const {
      virtualSettings: { isBriefcaseOpen },
      virtualEvent: { isQuickViewOpen, briefcaseLoading, briefcaseEmailLoading },
    } = this.props

    return (
      <>
        <Modal
          visible={isBriefcaseOpen}
          style={{
            top: '0px',
            maxHeight: 'calc(100vh - 180px)',
          }}
          wrapClassName="downloadModal"
          width="60vmax"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h3>The Briefcase</h3>
            </Col>
          </Row>
          <Row gutter={24} id="modalContent" className={isQuickViewOpen ? '' : 'modalContent'}>
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 185px)">
              <Col span={24}>
                <Button
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined />}
                  onClick={this.downloadBriefcase}
                  size="medium"
                  style={{ float: 'right', marginTop: "5px" }}
                  loading={briefcaseEmailLoading}
                >
                  Download Briefcase
                </Button>
                <br />
                <br />
                {isQuickViewOpen ? (
                  <QuickView />
                ) : (
                  <Skeleton loading={briefcaseLoading} active>
                    <DocumentList />
                  </Skeleton>
                )}
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Briefcase
